import React from "react"

export default ({props}) => {
	// console.log(props)
	const items = props.field_text_plain_long_repeater.map((item, key) => <li key={key}>{item}</li> )
	return (
  <ul className="card-columns" style={{ columnCount: props.field_number_of_columns, marginLeft: 0, textAlign: "left" }}>
		{items}
  </ul>
	)
}

// list-unstyled is a bootstrap class for ul to remove bullets