import React from "react"
import { Helmet } from 'react-helmet'

export default ({props}) => {
	// console.log(data.field_html.processed)
	// let test = function () { console.log("test") }
	return (
		<div>
			<div dangerouslySetInnerHTML={{ __html: props.field_html.processed }} />
			<Helmet>
				{/* <script></script> */}
				{/* <script>{`${test()}`}</script> */}
			</Helmet>
		</div>
	)
}
