import React from "react"
import { Button } from "react-bootstrap"

export default ({props}) => {
	return (
		props.relationships.field_button_s.map((button, key) => {
			let variant = (key%2 === 0) ? "primary" : "secondary"
			return (
				<Button href={button.field_link.uri.split(":")[1]} variant={variant} size="md" key={key}>
					{button.field_link.title}
				</Button>
			)
		})
	)
}
