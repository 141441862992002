import React from "react"

export default ({props}) => {
	let type = props.field_heading_type
	let value = props.field_heading
	switch (type) {
		case "h1": return <h1 dangerouslySetInnerHTML={{ __html: value }} />
		case "h2": return <h2 dangerouslySetInnerHTML={{ __html: value }} />
		case "h3": return <h3 dangerouslySetInnerHTML={{ __html: value }} />
		case "h4": return <h4 dangerouslySetInnerHTML={{ __html: value }} />
		case "h5": return <h5 dangerouslySetInnerHTML={{ __html: value }} />
		case "h6": return <h6 dangerouslySetInnerHTML={{ __html: value }} />
		default: return <p dangerouslySetInnerHTML={{ __html: value }} />
	}
}
