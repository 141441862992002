import React, { Component } from "react"

export default class FormTest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dynamics: false,
      form_id: this.props.props.field_form_id,
      form_id_2: this.props.props.field_form_id_2,
    }
  }

  componentDidMount() {
    const mountExternal = () => {
      // Create script Dynamics element
      const s = document.createElement("script")
      s.async = s.defer = false
      s.src = `https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.57.1054.0`
      s.id = "ms-dynamics-external-script"

      if (document.body) document.body.appendChild(s)
    }
    const mountInternal = () => {
      const s = document.createElement("script")
      s.async = s.defer = false
      s.src = `../../../dynamics.js`
      s.id = "ms-dynamics-internal-script"
      s.setAttribute("form-id-2", this.state.form_id_2)
      if (document.body) document.body.appendChild(s)
    }

    // Check for Dynamics script
    if (window.dynamics) {
      // Scripts are loaded but haven't run -> run them
      if (this.state.dynamics === false) {
        document.getElementById("ms-dynamics-internal-script").remove()
        mountInternal()
      }
    } else {
      mountExternal()
      mountInternal()

      // Run Instagram function to show embeds
      if (window.dynamics && this.state.dynamics === false) {
        console.log("Calling an unhandled exception --Clint")
        // document.getElementById('ms-dynamics-internal-script').remove()
        // mountInternal()
      }
      // Set state so the process doesn't run again
      this.setState({ dynamics: true })
    }
  }

  render() {
    return (
      <div>
        <div data-form-block-id={this.state.form_id}></div>
        <div id={this.state.form_id_2}></div>
      </div>
    )
  }
}
