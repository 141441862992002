import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, Carousel } from "react-bootstrap"


const CarouselRibbon = (data) => {
	// console.log(data)

	// Gracefully handle unknown types
	let type
	try { type = data.props.relationships.field_content_type.info } catch (e) {}

	let entries
	switch (type) {
		case "Case Study (Ribbon)":
			entries = data.data.allNodeCaseStudy.edges
			break
		default: entries = null
	}

	let consItems = (entries) => {
		const rtn = []
		// remove items without images
		for (let i=0; i<entries.length; i++) {
			if (! entries[i].node.relationships.field_icon) entries.splice(i,i+1)
		}
		// assemble slides
		for (let i=0; i<entries.length; i++) {
			if (i % 4 === 0) {
				rtn.push( {children: [entries[i]] })
			}
			else {
				rtn[rtn.length-1].children.push(entries[i])
			}
		}
		return rtn;
	}
	let items = consItems(entries)

	return (
        <Container style={{paddingBottom: "30px"}} fluid={true}>
			<Carousel
				className="p-carousel-ribbon"
				controls={false}
				indicators={true}
				interval={6000}
				>
			{ items.map((item, key) => {
				return (
                    <Carousel.Item key={key}>
						<Container fluid={true}>
							<Row className="justify-content-center">
							{item.children.map((i, key) => {
								// console.log(key)
								return (
                                    <Col xs={4} sm={3} key={key} className={ key > 2 ? "d-none d-sm-block" : "" }>
										<GatsbyImage
                                            image={i.node.relationships.field_icon.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData}
                                            imgStyle={{ objectFit: "fill" }}
                                            alt={i.node.relationships.field_icon.field_media_image.alt} />
									</Col>
                                );
							})}
							</Row>
						</Container>
					</Carousel.Item>
                );
			})}
			</Carousel>
		</Container>
    );
}

export default props => (
  <StaticQuery
    query={graphql`{
  allNodeCaseStudy {
    edges {
      node {
        id
        title
        relationships {
          field_icon {
            field_media_image {
              alt
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`}
    render={data => <CarouselRibbon data={data} {...props} />}
  />
)
