import React from "react"

export default ({ props }) => {
  // console.log(props)

  return props.relationships.field_paragraph_s.map((paragraph, key) => {
    return (
      <p
        key={key}
        dangerouslySetInnerHTML={{ __html: paragraph.field_paragraph }}
      />
    )
  })
}
