import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Section from "./section"
import Container from "./container"
import Column from "./column"

const Bricks = ({ bricks }) => {
  const query = useStaticQuery(graphql`{
  allEckPageBodySection {
    edges {
      node {
        drupal_id
        relationships {
          field_background_gradient {
            name
          }
        }
      }
    }
  }
  allEckPageBodyContainer {
    edges {
      node {
        id
        drupal_id
        field_row_justification
        field_fluid
        field_classes
      }
    }
  }
  allEckPageBodyColumn {
    edges {
      node {
        drupal_id
        field_classes
        relationships {
          field_column_spans {
            field_span_md
            field_span_xl
            field_span_xs
          }
          field_components {
            ... on paragraph__component_accordion {
              internal {
                type
              }
              field_number_of_columns
              relationships {
                field_question_answer {
                  field_answer {
                    processed
                  }
                  field_question {
                    processed
                  }
                }
              }
            }
            ... on paragraph__component_button_s {
              internal {
                type
              }
              relationships {
                field_button_s {
                  field_link {
                    title
                    uri
                  }
                }
              }
            }
            ... on paragraph__component_heading {
              internal {
                type
              }
              field_heading
              field_heading_type
            }
            ... on paragraph__component_html {
              internal {
                type
              }
              field_html {
                processed
              }
            }
            ... on paragraph__component_paragraph_s {
              internal {
                type
              }
              relationships {
                field_paragraph_s {
                  field_paragraph
                }
              }
            }
            ... on paragraph__component_form {
              internal {
                type
              }
              field_form_id
              field_form_id_2
            }
            ... on paragraph__component_image {
              internal {
                type
              }
              field_fluid
              relationships {
                field_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            ... on paragraph__component_list {
              internal {
                type
              }
              field_number_of_columns
              field_text_plain_long_repeater
            }
            ... on paragraph__component_carousel_ribbon {
              id
              internal {
                type
              }
              relationships {
                field_content_type {
                  info
                  relationships {
                    paragraph__component_carousel_ribbon {
                      id
                    }
                  }
                }
              }
            }
            ... on paragraph__component_industries_index {
              drupal_id
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
}
`)
  // console.log(query)

  if (bricks === undefined || bricks.length === 0) return null

  return bricks.map((brick, index) => {
    //////////////////////////////////////////////////
    // TYPE: Section
    if (brick.type === "eck_page_body__section") {
      const section = findBrick(query.allEckPageBodySection.edges, brick.id)
      // PROPS:
      // - id
      let id = "ID_" + brick.id
      // - background
      let background = { format: undefined, data: undefined }
      if (section.relationships.field_background_gradient) {
        background.format = "gradient"
        background.data = section.relationships.field_background_gradient.name
      }
      return (
        <Section
          key={index}
          id={id}
          background={background}
          children={brick.children}
          bricks={1}
        />
      )
    }
      //////////////////////////////////////////////////
    // TYPE: Container
    else if (brick.type === "eck_page_body__container") {
      const container = findBrick(query.allEckPageBodyContainer.edges, brick.id)
      return (
        <Container
          id={`ID_${brick.id}`}
          className={container.field_classes}
          fluid={container.field_fluid}
          row_justification={container.field_row_justification}
          children={brick.children}
          key={index}
        />
      )
    }
      //////////////////////////////////////////////////
    // TYPE: Column
    else if (brick.type === "eck_page_body__column") {
      let column = query.allEckPageBodyColumn.edges.find(edge => {
        return edge.node.drupal_id === brick.id ? true : false
      }).node
      return (
        <Column
          xs={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_xs
          }
          md={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_md
          }
          xl={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_xl
          }
          components={column.relationships.field_components}
          children={brick.children}
          key={index}
        />
      )
    } else
      return (
        <p key={index} className={`text-danger`}>
          Requested content not known (generated by Bricks.js)
        </p>
      )
  })
}

export default Bricks

function findBrick(edges, id) {
  return edges.find(edge => {
    return edge.node.drupal_id === id ? true : false
  }).node
}
