import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"

import Bricks from "./bricks"

//var classNames = require("classnames")

class Section extends React.Component {
  render() {
    let { id, className, background, background_image, ...rest } = this.props
    return (
      <section
        {...rest}
        id={id}
        className={classNames(className, {
          dark: this.props.background && this.props.background.format,
        })}
        children={<Children props={this.props} />}
      />
    )
  }
}
export default Section

const Children = ({ props }) => {
  //console.log(props)
  return (
    <>
      {props.background_image && (
        <GatsbyImage
          imgStyle={{ objectFit: "cover" }}
          image={{ ...props.background_image.childImageSharp.gatsbyImageData }}
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
          }}
        />
      )}
      {props.background && props.background.format === "gradient" && (
        <div
          className={classNames("cover-section", {
            "gradient-light-blue": props.background.data === "light-blue",
            "gradient-dark-blue": props.background.data === "dark-blue",
          })}
        />
      )}
      {props.bricks ? <Bricks bricks={props.children} /> : props.children}
    </>
  )
}
