import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default ({ props }) => {
  // console.log(props)

  const field_media_image =
    props.relationships.field_image.relationships.field_media_image
  return field_media_image.localFile.childImageSharp !== null ? (
    <Image
      data={field_media_image}
      alt={props.relationships.field_image.field_media_image.alt}
    />
  ) : (
    <SVG
      src={field_media_image.localFile.publicURL}
      alt={props.relationships.field_image.field_media_image.alt}
      fluid={props.field_fluid}
    />
  )
}

const Image = ({ data, alt }) => {
  return (
    <GatsbyImage
      className="d-block w-100"
      imgStyle={{ objectFit: "cover" }}
      // sizes={{ ...data.localFile.childImageSharp.gatsbyImageData }}
      image={{ ...data.localFile.childImageSharp.gatsbyImageData }}
      alt={alt}
    />
  )
}

const SVG = ({ src, alt, fluid }) => {
  return (
    <object
      type="image/svg+xml"
      data={src}
      alt={alt}
      className={`${!fluid ? "not-fluid" : ""}`}
      style={{ maxWidth: "100%", marginBottom: 0 }}
    >
      <p>Your browser doesn't support SVG</p>
    </object>
  )
}
