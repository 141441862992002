import React from "react"
import { Accordion, Container, Row, Card } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"

		// const Toggle = styled(Accordion.Toggle)`
		// 	color: green;
		// `

export default ({props}) => {
	// console.log(props)

	const items = props.relationships.field_question_answer.map((item, index) => {
		// console.log(item)

		return (
			<Accordion defaultActiveKey={null} key={index} style={{transition: "1s"}} className={"col-12 col-md-6 col-xl-5"}>
				<Card style={{color: "#ffffff", backgroundColor: "initial", border: "initial"}}>
					<Accordion.Toggle as={"button"} className={"container btn-clean text-left"} eventKey="0">
						<Row>
							<div className={"d-flex mt-auto mb-auto"} style={{width: "40px", height: "40px"}}>
								<span style={{marginTop: "auto", marginBottom: "auto", padding: "0.4em", fontSize: "0.8em", lineHeight: 0, borderRadius: "0.4em", color: "#000000", backgroundColor: "#ffffff"}}><FaPlus /></span>
							</div>
							<div dangerouslySetInnerHTML={{ __html: item.field_question.processed }} className={"d-flex mt-auto mb-auto"} style={{width: "calc(100% - 40px)"}} />
						</Row>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Container style={{paddingTop: "5px", paddingBottom: "15px"}}>
							<div dangerouslySetInnerHTML={{ __html: item.field_answer.processed }} style={{marginLeft: "40px"}} />
						</Container>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	})

	return (
		<ul className="list-unstyled row justify-content-center"
		style={{
			// columnCount: props.field_number_of_columns,
			margin: 0,
			textAlign: "left"
			}}>
			{items}
		</ul>
	)
}